import moment from "moment";
import ReactGA from "react-ga4";
import { publicIpv4 } from "public-ip";
import { lazy, Suspense, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import "moment/locale/ar";

import { GOOGLE_ANALYTICS_MEASUREMENT_ID } from "./config";

import { lazyRetry } from "./utils/common.utils";
import { isEmpty } from "./utils/validation.utils";
import { getIpAddress, setIpAddress } from "./utils/storage.utils";

import { LANGUAGES } from "./constants/common.constant";
import {
  PERMISSION_ADMIN_PAGES,
  PERMISSION_SUBSCRIBER_PAGES,
} from "./constants/permission.constant";
import { USER_ROLES } from "./constants/user.constant";

import {
  getAuthorizePages,
  getIsGlobalInitialized,
  getIsGlobalLoading,
  getIsHasAdvancedSubscription,
  getIsHasSubscription,
} from "./store/global/global.selector";
import { resetAdminReducer } from "./store/admin/admin.action";
import { resetAdvertisementReducer } from "./store/advertisement/advertisement.action";
import { resetAnnouncementReducer } from "./store/announcement/announcement.action";
import { resetAppReducer } from "./store/application/application.action";
import { resetAttendanceReducer } from "./store/attendance/attendance.action";
import { resetAuthReducer } from "./store/authentication/authentication.action";
import {
  getSignInSuccess,
  getSignOutSuccess,
  getSignUpSuccess,
  getUser,
} from "./store/authentication/authentication.selector";
import { resetBranchReducer } from "./store/branch/branch.action";
import { resetCardReducer } from "./store/card/card.action";
import {
  resetComponentReducer,
  setIsHasThanksPage,
} from "./store/component/component.action";
import {
  getIsHasGettingStarted,
  getIsHasThanksPage,
} from "./store/component/component.selector";
import { resetCountryReducer } from "./store/country/country.action";
import { resetCustomerReducer } from "./store/customer/customer.action";
import { resetDashboardReducer } from "./store/dashboard/dashboard.action";
import { resetDiscountReducer } from "./store/discount/discount.action";
import { resetEventReducer } from "./store/event/event.action";
import { resetExportReducer } from "./store/export/export.action";
import { resetExtendPeriodReducer } from "./store/extend-period/extend-period.action";
import { resetFormCustomerReducer } from "./store/form-customer/form-customer.action";
import { resetFormOrderReducer } from "./store/form-order/form-order.action";
import { resetGatewayReducer } from "./store/gateway/gateway.action";
import { resetBranchReducer as resetHeaderBranchReducer } from "./store/header-branch/header-branch.action";
import { resetIngredientReducer } from "./store/ingredient/ingredient.action";
import { resetIngredientHistoryReducer } from "./store/ingredient-history/ingredient-history.action";
import { resetIntegrationReducer } from "./store/integration/integration.action";
import { resetKitchenGroupReducer } from "./store/kitchen-group/kitchen-group.action";
import { resetMarketReducer } from "./store/market/market.action";
import { resetMerchantReducer } from "./store/merchant/merchant.action";
import { resetMsegatReducer } from "./store/msegat/msegat.action";
import { resetOrderReducer } from "./store/order/order.action";
import { resetOrderProductReducer } from "./store/order-product/order-product.action";
import { resetPaymentDeviceReducer } from "./store/payment-device/payment-device.action";
import { resetPaymentMethodReducer } from "./store/payment-method/payment-method.action";
import { resetPaymentMethodSettingReducer } from "./store/payment-method-setting/payment-method-setting.action";
import { resetPermissionReducer } from "./store/permission/permission.action";
import { resetPermissionAdminReducer } from "./store/permission-admin/permission-admin.action";
import { resetPermissionMerchantReducer } from "./store/permission-merchant/permission-merchant.action";
import { resetPermissionSubscriberReducer } from "./store/permission-subscriber/permission-subscriber.action";
import { resetPrinterReducer } from "./store/printer/printer.action";
import { resetPrinterTypeReducer } from "./store/printer-type/printer-type.action";
import { resetProductCategoryReducer } from "./store/product-category/product-category.action";
import { resetProductCategorySortReducer } from "./store/product-category-sort/product-category-sort.action";
import { resetProductModifierReducer } from "./store/product-modifier/product-modifier.action";
import { resetProductReducer } from "./store/product/product.action";
import { resetRecipeReducer } from "./store/recipe/recipe.action";
import { resetReportReducer } from "./store/report/report.action";
import { resetRestockRequestReducer } from "./store/restock-request/restock-request.action";
import { resetScheduleReducer } from "./store/schedule/schedule.action";
import { resetSectorReducer } from "./store/sector/sector.action";
import { resetBranchReducer as resetSelectBranchReducer } from "./store/select-branch/select-branch.action";
import { resetCashierReducer as resetSelectCashierReducer } from "./store/select-cashier/select-cashier.action";
import { resetCountryReducer as resetSelectCountryReducer } from "./store/select-country/select-country.action";
import { resetCustomerReducer as resetSelectCustomerReducer } from "./store/select-customer/select-customer.action";
import { resetIngredientReducer as resetSelectIngredientReducer } from "./store/select-ingredient/select-ingredient.action";
import { resetMarketReducer as resetSelectMarketReducer } from "./store/select-market/select-market.action";
import { resetProductReducer as resetSelectProductReducer } from "./store/select-product/select-product.action";
import { resetProductCategoryReducer as resetSelectProductCategoryReducer } from "./store/select-product-category/select-product-category.action";
import { resetSettingReducer } from "./store/setting/setting.action";
import { resetSubscriberReducer } from "./store/subscriber/subscriber.action";
import { resetSummaryReducer } from "./store/summary/summary.action";
import { resetTableReducer } from "./store/table/table.action";
import { resetTaxReducer } from "./store/tax/tax.action";
import { resetTermConditionReducer } from "./store/term-condition/term-condition.action";
import { resetTopUpReducer } from "./store/top-up/top-up.action";
import { resetUserAdminReducer } from "./store/user-admin/user-admin.action";
import { resetUserCustomerReducer } from "./store/user-customer/user-customer.action";
import { resetUserDriverReducer } from "./store/user-driver/user-driver.action";
import { resetUserMerchantReducer } from "./store/user-merchant/user-merchant.action";
import { resetUserSubscriberReducer } from "./store/user-subscriber/user-subscriber.action";
import { resetUserSupplierReducer } from "./store/user-supplier/user-supplier.action";
import { resetVerificationReducer } from "./store/verification/verification.action";
import { resetWathqReducer } from "./store/wathq/wathq.action";
import { resetWhatsappReducer } from "./store/whatsapp/whatsapp.action";

import Loading from "./routes/loading/loading.route";

const Account = lazy(() =>
  lazyRetry(() => import("./routes/account/account.route"))
);
const AccountDetails = lazy(() =>
  lazyRetry(() => import("./routes/account-details/account-details.route"))
);
const AdminSubscription = lazy(() =>
  lazyRetry(() =>
    import("./routes/admin-subscription/admin-subscription.route")
  )
);
const Advertisement = lazy(() =>
  lazyRetry(() => import("./routes/advertisement/advertisement.route"))
);
const Application = lazy(() =>
  lazyRetry(() => import("./routes/application/application.route"))
);
const ApplicationExtension = lazy(() =>
  lazyRetry(() =>
    import("./routes/application-extension/application-extension.route")
  )
);
const Authentication = lazy(() =>
  lazyRetry(() => import("./routes/authentication/authentication.route"))
);
const AuthenticationEmailVerification = lazy(() =>
  lazyRetry(() =>
    import(
      "./routes/authentication-email-verification/authentication-email-verification.route"
    )
  )
);
const Branch = lazy(() =>
  lazyRetry(() => import("./routes/branch/branch.route"))
);
const Country = lazy(() =>
  lazyRetry(() => import("./routes/country/country.route"))
);
const Customer = lazy(() =>
  lazyRetry(() => import("./routes/customer/customer.route"))
);
const CustomerDetails = lazy(() =>
  lazyRetry(() => import("./routes/customer-details/customer-details.route"))
);
const Dashboard = lazy(() =>
  lazyRetry(() => import("./routes/dashboard/dashboard.route"))
);
const Device = lazy(() =>
  lazyRetry(() => import("./routes/device/device.route"))
);
const Discount = lazy(() =>
  lazyRetry(() => import("./routes/discount/discount.route"))
);
// const DiscountCreate = lazy(() =>
//   lazyRetry(() => import("./routes/discount-create/discount-create.route"))
// );
const DiscountCreateCoupon = lazy(() =>
  lazyRetry(() =>
    import("./routes/discount-create-coupon/discount-create-coupon.route")
  )
);
const DiscountCreateOffer = lazy(() =>
  lazyRetry(() =>
    import("./routes/discount-create-offer/discount-create-offer.route")
  )
);
const DiscountCreatePromotion = lazy(() =>
  lazyRetry(() =>
    import("./routes/discount-create-promotion/discount-create-promotion.route")
  )
);
// const DiscountEdit = lazy(() =>
//   lazyRetry(() => import("./routes/discount-edit/discount-edit.route"))
// );
const DiscountEditCoupon = lazy(() =>
  lazyRetry(() =>
    import("./routes/discount-edit-coupon/discount-edit-coupon.route")
  )
);
const DiscountEditOffer = lazy(() =>
  lazyRetry(() =>
    import("./routes/discount-edit-offer/discount-edit-offer.route")
  )
);
const DiscountEditPromotion = lazy(() =>
  lazyRetry(() =>
    import("./routes/discount-edit-promotion/discount-edit-promotion.route")
  )
);
// const Driver = lazy(() =>
//   lazyRetry(() => import("./routes/driver/driver.route"))
// );
// const DriverCreate = lazy(() =>
//   lazyRetry(() => import("./routes/driver-create/driver-create.route"))
// );
// const DriverDashboard = lazy(() =>
//   lazyRetry(() => import("./routes/driver-dashboard/driver-dashboard.route"))
// );
const GeneralSettings = lazy(() =>
  lazyRetry(() => import("./routes/general-settings/general-settings.route"))
);
const GettingStarted = lazy(() =>
  lazyRetry(() => import("./widgets/getting-started/getting-started.widget"))
);
const GlobalAnnouncement = lazy(() =>
  lazyRetry(() =>
    import("./widgets/global-announcement/global-announcement.widget")
  )
);
const GlobalHandler = lazy(() =>
  lazyRetry(() => import("./widgets/global-handler/global-handler.widget"))
);
const IndexRedirect = lazy(() =>
  lazyRetry(() => import("./routes/index-redirect/index-redirect.route"))
);
const Inventory = lazy(() =>
  lazyRetry(() => import("./routes/inventory/inventory.route"))
);
const Kitchen = lazy(() =>
  lazyRetry(() => import("./routes/kitchen/kitchen.route"))
);
const KitchenDetails = lazy(() =>
  lazyRetry(() => import("./routes/kitchen-details/kitchen-details.route"))
);
const Merchant = lazy(() =>
  lazyRetry(() => import("./routes/merchant/merchant.route"))
);
const MerchantCreate = lazy(() =>
  lazyRetry(() => import("./routes/merchant-create/merchant-create.route"))
);
const MessagesPopup = lazy(() =>
  lazyRetry(() => import("./widgets/messages-popup/messages-popup.widget"))
);
const NotFound = lazy(() =>
  lazyRetry(() => import("./routes/not-found/not-found.route"))
);
const Order = lazy(() => lazyRetry(() => import("./routes/order/order.route")));
const OrderCreate = lazy(() =>
  lazyRetry(() => import("./routes/order-create/order-create.route"))
);
const OrderEdit = lazy(() =>
  lazyRetry(() => import("./routes/order-edit/order-edit.route"))
);
const OrderCustomer = lazy(() =>
  lazyRetry(() => import("./routes/order-customer/order-customer.route"))
);
const OrderWaiting = lazy(() =>
  lazyRetry(() => import("./routes/order-waiting/order-waiting.route"))
);
const Rating = lazy(() =>
  lazyRetry(() => import("./routes/rating/rating.route"))
);
const ReportOrder = lazy(() =>
  lazyRetry(() => import("./routes/report-order/report-order.route"))
);
const ReportSummary = lazy(() =>
  lazyRetry(() => import("./routes/report-summary/report-summary.route"))
);
const PaymentCheckout = lazy(() =>
  lazyRetry(() => import("./routes/payment-checkout/payment-checkout.route"))
);
const PaymentDevice = lazy(() =>
  lazyRetry(() => import("./routes/payment-device/payment-device.route"))
);
const PaymentMethod = lazy(() =>
  lazyRetry(() => import("./routes/payment-method/payment-method.route"))
);
const PaymentMethodSetting = lazy(() =>
  lazyRetry(() =>
    import("./routes/payment-method-setting/payment-method-setting.route")
  )
);
const PaymentRedirect = lazy(() =>
  lazyRetry(() => import("./routes/payment-redirect/payment-redirect.route"))
);
// const PaymentTransfer = lazy(() => lazyRetry(() => import("./routes/payment-transfer/payment-transfer.route")));
const Permission = lazy(() =>
  lazyRetry(() => import("./routes/permission/permission.route"))
);
const Printer = lazy(() =>
  lazyRetry(() => import("./routes/printer/printer.route"))
);
const PrinterType = lazy(() =>
  lazyRetry(() => import("./routes/printer-type/printer-type.route"))
);
const Product = lazy(() =>
  lazyRetry(() => import("./routes/product/product.route"))
);
const ProductCategory = lazy(() =>
  lazyRetry(() => import("./routes/product-category/product-category.route"))
);
const ProductCreate = lazy(() =>
  lazyRetry(() => import("./routes/product-create/product-create.route"))
);
const ProductEdit = lazy(() =>
  lazyRetry(() => import("./routes/product-edit/product-edit.route"))
);
const Profile = lazy(() =>
  lazyRetry(() => import("./routes/profile/profile.route"))
);
const RestockRequest = lazy(() =>
  lazyRetry(() => import("./routes/restock-request/restock-request.route"))
);
const Recipe = lazy(() =>
  lazyRetry(() => import("./routes/recipe/recipe.route"))
);
const Purchase = lazy(() =>
  lazyRetry(() => import("./routes/purchase/purchase.route"))
);
const Schedule = lazy(() =>
  lazyRetry(() => import("./routes/schedule/schedule.route"))
);
const Sector = lazy(() =>
  lazyRetry(() => import("./routes/sector/sector.route"))
);
const SectorCreate = lazy(() =>
  lazyRetry(() => import("./routes/sector-create/sector-create.route"))
);
const SectorEdit = lazy(() =>
  lazyRetry(() => import("./routes/sector-edit/sector-edit.route"))
);
const SelectCommonHandler = lazy(() =>
  lazyRetry(() =>
    import("./widgets/select-common-handler/select-common-handler.widget")
  )
);
const Settings = lazy(() =>
  lazyRetry(() => import("./routes/settings/settings.route"))
);
const ShortUrl = lazy(() =>
  lazyRetry(() => import("./routes/short-url/short-url.route"))
);
const SignIn = lazy(() =>
  lazyRetry(() => import("./routes/sign-in/sign-in.route"))
);
const SignUp = lazy(() =>
  lazyRetry(() => import("./routes/sign-up/sign-up.route"))
);
const SignUpRedirect = lazy(() =>
  lazyRetry(() => import("./routes/sign-up-redirect/sign-up-redirect.route"))
);
const Sms = lazy(() => lazyRetry(() => import("./routes/sms/sms.route")));
const Subscriber = lazy(() =>
  lazyRetry(() => import("./routes/subscriber/subscriber.route"))
);
const SubscriberSubscription = lazy(() =>
  lazyRetry(() =>
    import("./routes/subscriber-subscription/subscriber-subscription.route")
  )
);
const SubscriberCreate = lazy(() =>
  lazyRetry(() => import("./routes/subscriber-create/subscriber-create.route"))
);
const SubscriberDetails = lazy(() =>
  lazyRetry(() =>
    import("./routes/subscriber-details/subscriber-details.route")
  )
);
const Subscription = lazy(() =>
  lazyRetry(() => import("./routes/subscription/subscription.route"))
);
const Supplier = lazy(() =>
  lazyRetry(() => import("./routes/supplier/supplier.route"))
);
const Table = lazy(() => lazyRetry(() => import("./routes/table/table.route")));
const Tax = lazy(() => lazyRetry(() => import("./routes/tax/tax.route")));
const TermCondition = lazy(() =>
  lazyRetry(() => import("./routes/term-condition/term-condition.route"))
);
const Tidio = lazy(() =>
  lazyRetry(() => import("./widgets/tidio/tidio.widget"))
);
const Toast = lazy(() =>
  lazyRetry(() => import("./components/toast/toast.component"))
);

export const subscriberRoutePages = [
  {
    key: PERMISSION_SUBSCRIBER_PAGES.DASHBOARD,
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.TABLE,
    path: "/table",
    element: <Table />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.ORDER,
    path: "/order",
    element: <Order />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.ORDER,
    path: "/order/create",
    element: <OrderCreate />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.ORDER,
    path: "/order/edit/:orderId",
    element: <OrderEdit />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.ORDER,
    path: "/order/customer",
    element: <OrderCustomer />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.ORDER,
    path: "/order/waiting",
    element: <OrderWaiting />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.RATING,
    path: "/rating",
    element: <Rating />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.REPORT,
    path: "/report/summary",
    element: <ReportSummary />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.REPORT,
    path: "/report/order",
    element: <ReportOrder />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.PRODUCT,
    path: "/product",
    element: <Product />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.PRODUCT,
    path: "/product/create",
    element: <ProductCreate />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.PRODUCT,
    path: "/product/edit/:productId",
    element: <ProductEdit />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.DISCOUNT,
    path: "/discount",
    element: <Discount />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.DISCOUNT,
    path: "/discount/create/coupon",
    element: <DiscountCreateCoupon />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.DISCOUNT,
    path: "/discount/create/offer",
    element: <DiscountCreateOffer />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.DISCOUNT,
    path: "/discount/create/promotion",
    element: <DiscountCreatePromotion />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.DISCOUNT,
    path: "/discount/edit/coupon/:discountId",
    element: <DiscountEditCoupon />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.DISCOUNT,
    path: "/discount/edit/offer/:discountId",
    element: <DiscountEditOffer />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.DISCOUNT,
    path: "/discount/edit/promotion/:discountId",
    element: <DiscountEditPromotion />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.KITCHEN,
    path: "/kitchen",
    element: <Kitchen />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.KITCHEN,
    path: "/kitchen/:kitchenGroupId",
    element: <KitchenDetails />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.APPLICATION,
    path: "/application",
    element: <ApplicationExtension />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.CUSTOMER,
    path: "/customer",
    element: <Customer />,
  },
  // {
  //   key: PERMISSION_SUBSCRIBER_PAGES.DRIVER,
  //   path: "/connected-driver",
  //   element: <Driver />,
  // },
  // {
  //   key: PERMISSION_SUBSCRIBER_PAGES.DRIVER,
  //   path: "/connected-driver/create",
  //   element: <DriverCreate />,
  // },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.INVENTORY,
    path: "/inventory",
    element: <Inventory />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.ORDERS_OF_PURCHASE,
    path: "/restock-request",
    element: <RestockRequest />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.RECIPE,
    path: "/recipe",
    element: <Recipe />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.SUPPLIER,
    path: "/supplier",
    element: <Supplier />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.PURCHASE,
    path: "/purchase",
    element: <Purchase />,
  },
  {
    key: [
      PERMISSION_SUBSCRIBER_PAGES.AUTHENTICATION,
      PERMISSION_SUBSCRIBER_PAGES.PROFILE,
      PERMISSION_SUBSCRIBER_PAGES.BRANCH,
      PERMISSION_SUBSCRIBER_PAGES.DEVICE,
      PERMISSION_SUBSCRIBER_PAGES.PERMISSION,
      PERMISSION_SUBSCRIBER_PAGES.ADVERTISEMENT,
      PERMISSION_SUBSCRIBER_PAGES.SCHEDULE,
      PERMISSION_SUBSCRIBER_PAGES.ACCOUNT,
      PERMISSION_SUBSCRIBER_PAGES.PAYMENT_METHOD,
      PERMISSION_SUBSCRIBER_PAGES.TAX,
      PERMISSION_SUBSCRIBER_PAGES.SUBSCRIPTION,
      PERMISSION_SUBSCRIBER_PAGES.GENERAL_SETTINGS,
    ],
    path: "/settings",
    element: <Settings />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.AUTHENTICATION,
    path: "/settings/authentication",
    element: <Authentication />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.PROFILE,
    path: "/settings/profile",
    element: <Profile />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.BRANCH,
    path: "/settings/branch",
    element: <Branch />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.DEVICE,
    path: "/settings/device",
    element: <Device />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.DEVICE,
    path: "/settings/device/printer",
    element: <Printer />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.DEVICE,
    path: "/settings/device/payment-device",
    element: <PaymentDevice />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.PERMISSION,
    path: "/settings/permission",
    element: <Permission />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.CUSTOMER,
    path: "/customer/:customerId",
    element: <CustomerDetails />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.ADVERTISEMENT,
    path: "/settings/advertisement",
    element: <Advertisement />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.SCHEDULE,
    path: "/settings/schedule",
    element: <Schedule />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.ACCOUNT,
    path: "/settings/account",
    element: <Account />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.ACCOUNT,
    path: "/settings/account/:accountId",
    element: <AccountDetails />,
  },
  // {
  //   key: PERMISSION_SUBSCRIBER_PAGES.PAYMENT_TRANSFER,
  //   path: "/settings/payment-transfer",
  //   element: <PaymentTransfer />,
  // },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.PAYMENT_METHOD,
    path: "/settings/payment-method",
    element: <PaymentMethodSetting />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.TAX,
    path: "/settings/tax",
    element: <Tax />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.SUBSCRIPTION,
    path: "/settings/subscription",
    element: <Subscription />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.GENERAL_SETTINGS,
    path: "/settings/general",
    element: <GeneralSettings />,
  },
];

export const adminRoutePages = [
  {
    key: PERMISSION_ADMIN_PAGES.DASHBOARD,
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.PRODUCT_CATEGORY,
    path: "/product-category",
    element: <ProductCategory />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.PRINTER_TYPE,
    path: "/printer-type",
    element: <PrinterType />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.PAYMENT_METHOD,
    path: "/payment-method",
    element: <PaymentMethod />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.COUNTRY,
    path: "/country",
    element: <Country />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.SECTOR,
    path: "/sector",
    element: <Sector />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.SECTOR,
    path: "/sector/create",
    element: <SectorCreate />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.SECTOR,
    path: "/sector/edit/:sectorId",
    element: <SectorEdit />,
  },
  {
    key: PERMISSION_SUBSCRIBER_PAGES.APPLICATION,
    path: "/application",
    element: <Application />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.SMS,
    path: "/sms",
    element: <Sms />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.SUBSCRIBER,
    path: "/subscriber",
    element: <Subscriber />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.SUBSCRIBER,
    path: "/subscriber/subscription",
    element: <SubscriberSubscription />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.SUBSCRIBER,
    path: "/subscriber/create",
    element: <SubscriberCreate />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.SUBSCRIBER,
    path: "/subscriber/:marketId",
    element: <SubscriberDetails />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.MERCHANT,
    path: "/merchant",
    element: <Merchant />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.MERCHANT,
    path: "/merchant/create",
    element: <MerchantCreate />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.SUBSCRIPTION,
    path: "/subscription",
    element: <AdminSubscription />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.REPORT,
    path: "/report/summary",
    element: <ReportSummary />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.REPORT,
    path: "/report/order",
    element: <ReportOrder />,
  },
  {
    key: [
      PERMISSION_ADMIN_PAGES.ACCOUNT,
      PERMISSION_ADMIN_PAGES.PERMISSION,
      PERMISSION_ADMIN_PAGES.TERM_CONDITION,
      PERMISSION_ADMIN_PAGES.GENERAL_SETTINGS,
    ],
    path: "/settings",
    element: <Settings />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.ACCOUNT,
    path: "/settings/account",
    element: <Account />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.PERMISSION,
    path: "/settings/permission",
    element: <Permission />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.TERM_CONDITION,
    path: "/settings/term-condition",
    element: <TermCondition />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.GENERAL_SETTINGS,
    path: "/settings/general",
    element: <GeneralSettings />,
  },
  ...subscriberRoutePages
    .filter(({ key }) => !Array.isArray(key))
    .map(({ path, element }) => ({
      key: PERMISSION_ADMIN_PAGES.SUBSCRIBER,
      path: `/subscriber/:marketId${path.replace("/settings", "")}`,
      element,
    })),
];

export const merchantRoutePages = [
  {
    key: PERMISSION_ADMIN_PAGES.DASHBOARD,
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.SUBSCRIBER,
    path: "/subscriber",
    element: <Subscriber />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.SUBSCRIBER,
    path: "/subscriber/create",
    element: <SubscriberCreate />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.SUBSCRIBER,
    path: "/subscriber/:marketId",
    element: <SubscriberDetails />,
  },
  {
    key: [PERMISSION_ADMIN_PAGES.ACCOUNT, PERMISSION_ADMIN_PAGES.PERMISSION],
    path: "/settings",
    element: <Settings />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.ACCOUNT,
    path: "/settings/account",
    element: <Account />,
  },
  {
    key: PERMISSION_ADMIN_PAGES.PERMISSION,
    path: "/settings/permission",
    element: <Permission />,
  },
  ...subscriberRoutePages
    .filter(({ key }) => !Array.isArray(key))
    .map(({ path, element }) => ({
      key: PERMISSION_ADMIN_PAGES.SUBSCRIBER,
      path: `/subscriber/:marketId${path.replace("/settings", "")}`,
      element,
    })),
];

const disabledBasicPages = [
  PERMISSION_SUBSCRIBER_PAGES.ADVERTISEMENT,
  PERMISSION_SUBSCRIBER_PAGES.APPLICATION,
  PERMISSION_SUBSCRIBER_PAGES.INVENTORY,
  PERMISSION_SUBSCRIBER_PAGES.KITCHEN,
  PERMISSION_SUBSCRIBER_PAGES.ORDERS_OF_PURCHASE,
  PERMISSION_SUBSCRIBER_PAGES.PAYMENT_METHOD,
  PERMISSION_SUBSCRIBER_PAGES.PERMISSION,
  PERMISSION_SUBSCRIBER_PAGES.PURCHASE,
  PERMISSION_SUBSCRIBER_PAGES.RATING,
  PERMISSION_SUBSCRIBER_PAGES.RECIPE,
  PERMISSION_SUBSCRIBER_PAGES.SUPPLIER,
];

export const getPermissionPages = (role) =>
  ({
    [USER_ROLES.USER_ADMIN]: adminRoutePages,
    [USER_ROLES.USER_MERCHANT]: merchantRoutePages,
    [USER_ROLES.USER_SUBSCRIBER]: subscriberRoutePages,
  }?.[role] ?? []);

ReactGA.initialize(GOOGLE_ANALYTICS_MEASUREMENT_ID);

const App = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isHasGettingStarted = useSelector(getIsHasGettingStarted);
  const isHasThanksPage = useSelector(getIsHasThanksPage);

  const isHasAdvancedSubscription = useSelector(getIsHasAdvancedSubscription);
  const isHasSubscription = useSelector(getIsHasSubscription);
  const isGlobalInitialized = useSelector(getIsGlobalInitialized);
  const isGlobalLoading = useSelector(getIsGlobalLoading);
  const authorizePages = useSelector(getAuthorizePages);
  const authenticationUser = useSelector(getUser);

  const signInSuccess = useSelector(getSignInSuccess);
  const signUpSuccess = useSelector(getSignUpSuccess);
  const signOutSuccess = useSelector(getSignOutSuccess);

  const ipAddress = getIpAddress();

  const { role } = authenticationUser ?? {};

  const [routePages, setRoutePages] = useState([]);

  document.body.dir = i18n.dir();
  moment.locale(
    Object.values(LANGUAGES).includes(i18n.language)
      ? i18n.language
      : LANGUAGES.EN
  );

  useEffect(() => {
    if (signInSuccess !== null || signOutSuccess !== null) {
      navigate("/");
    } else if (signUpSuccess !== null) {
      dispatch(setIsHasThanksPage(true));
      navigate("/thanks");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInSuccess, signUpSuccess, signOutSuccess]);

  useEffect(() => {
    if (signOutSuccess !== null) {
      dispatch(resetAdminReducer());
      dispatch(resetAdvertisementReducer());
      dispatch(resetAnnouncementReducer());
      dispatch(resetAppReducer());
      dispatch(resetAttendanceReducer());
      dispatch(resetAuthReducer());
      dispatch(resetBranchReducer());
      dispatch(resetCardReducer());
      dispatch(resetComponentReducer());
      dispatch(resetCountryReducer());
      dispatch(resetCustomerReducer());
      dispatch(resetDashboardReducer());
      dispatch(resetDiscountReducer());
      dispatch(resetEventReducer());
      dispatch(resetExportReducer());
      dispatch(resetExtendPeriodReducer());
      dispatch(resetFormCustomerReducer());
      dispatch(resetFormOrderReducer());
      dispatch(resetGatewayReducer());
      dispatch(resetHeaderBranchReducer());
      dispatch(resetIngredientReducer());
      dispatch(resetIngredientHistoryReducer());
      dispatch(resetIntegrationReducer());
      dispatch(resetKitchenGroupReducer());
      dispatch(resetMarketReducer());
      dispatch(resetMerchantReducer());
      dispatch(resetMsegatReducer());
      dispatch(resetOrderReducer());
      dispatch(resetOrderProductReducer());
      dispatch(resetPaymentDeviceReducer());
      dispatch(resetPaymentMethodReducer());
      dispatch(resetPaymentMethodSettingReducer());
      dispatch(resetPermissionReducer());
      dispatch(resetPermissionAdminReducer());
      dispatch(resetPermissionMerchantReducer());
      dispatch(resetPermissionSubscriberReducer());
      dispatch(resetPrinterReducer());
      dispatch(resetPrinterTypeReducer());
      dispatch(resetProductCategoryReducer());
      dispatch(resetProductCategorySortReducer());
      dispatch(resetProductModifierReducer());
      dispatch(resetProductReducer());
      dispatch(resetRecipeReducer());
      dispatch(resetReportReducer());
      dispatch(resetRestockRequestReducer());
      dispatch(resetScheduleReducer());
      dispatch(resetSectorReducer());
      dispatch(resetSelectBranchReducer());
      dispatch(resetSelectCashierReducer());
      dispatch(resetSelectCountryReducer());
      dispatch(resetSelectCustomerReducer());
      dispatch(resetSelectIngredientReducer());
      dispatch(resetSelectMarketReducer());
      dispatch(resetSelectProductReducer());
      dispatch(resetSelectProductCategoryReducer());
      dispatch(resetSettingReducer());
      dispatch(resetSubscriberReducer());
      dispatch(resetSummaryReducer());
      dispatch(resetTableReducer());
      dispatch(resetTaxReducer());
      dispatch(resetTermConditionReducer());
      dispatch(resetTopUpReducer());
      dispatch(resetUserAdminReducer());
      dispatch(resetUserCustomerReducer());
      dispatch(resetUserDriverReducer());
      dispatch(resetUserMerchantReducer());
      dispatch(resetUserSubscriberReducer());
      dispatch(resetUserSupplierReducer());
      dispatch(resetVerificationReducer());
      dispatch(resetWathqReducer());
      dispatch(resetWhatsappReducer());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signOutSuccess]);

  useEffect(() => {
    if (isEmpty(ipAddress)) (async () => setIpAddress(await publicIpv4()))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ipAddress]);

  useEffect(() => {
    const permissionPages = getPermissionPages(role);

    if (isHasAdvancedSubscription) {
      setRoutePages(permissionPages);
    } else {
      const disabledPagesHandler = ({ key }) =>
        Array.isArray(key)
          ? key.filter((k) => !disabledBasicPages.includes(k)).length > 0
          : !disabledBasicPages.includes(key);
      setRoutePages(permissionPages.filter(disabledPagesHandler));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHasAdvancedSubscription, role]);

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <GlobalHandler />
              <GlobalAnnouncement />
              <SelectCommonHandler />
              <MessagesPopup />
              <Tidio />
              <Toast />
              {isHasGettingStarted && <GettingStarted />}
            </>
          }
        >
          <Route index element={<IndexRedirect />} />
          <Route path="sign-in" element={<SignIn />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="short/:shortUrlKey" element={<ShortUrl />} />
          <Route
            path="verification/verify"
            element={<AuthenticationEmailVerification />}
          />
          <Route
            path="payment/checkout/:gatewayId"
            element={<PaymentCheckout />}
          />
          <Route
            path="payment/redirect/:gatewayId"
            element={<PaymentRedirect />}
          />
          {/* <Route path="driver/dashboard" element={<DriverDashboard />} /> */}
          {authenticationUser &&
            isGlobalInitialized &&
            [
              USER_ROLES.USER_ADMIN,
              USER_ROLES.USER_MERCHANT,
              USER_ROLES.USER_SUBSCRIBER,
            ].includes(role) && (
              <>
                {isHasThanksPage && (
                  <Route path="thanks" element={<SignUpRedirect />} />
                )}
                {isHasSubscription ? (
                  routePages
                    .filter(({ key }) =>
                      Array.isArray(key)
                        ? key.filter((k) => authorizePages.includes(k)).length >
                          0
                        : authorizePages.includes(key)
                    )
                    .map(({ path, element }, index) => (
                      <Route key={index} path={path} element={element} />
                    ))
                ) : (
                  <Route path="subscription" element={<Subscription />} />
                )}
              </>
            )}
          <Route
            path="*"
            element={isGlobalLoading ? <Loading /> : <NotFound />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default App;
